/**
Keyframes definition for the crossfade component images effect.

For each number of images that the component can use, a keyframe definition
needs to exist to make the transition animation work properly.

The percentage calc was made based on the above rules:

multiple-image-crossfade-[N] = Images number

1. 0%
2. ((100 / N) / 3) 
3. 100 / N
4. (100 / N) +  ((100 / N) / 3)
5. 100

Declaring keysframes statically, as the current version of material-ui
makeStyles function does not support keysframes declaration.

Initially supporting up to 10 images.
*/

@keyframes multiple-image-crossfade-2 {
  0% { opacity: 0; }
  16.66% { opacity: 1; }
  50% { opacity: 1; }
  66.66% { opacity: 0; }
  100% { opacity: 0; }
}

@keyframes multiple-image-crossfade-3 {
  0% { opacity: 0; }
  11% { opacity: 1; }
  33% { opacity: 1; }
  44% { opacity: 0; }
  100% { opacity: 0; }
}

@keyframes multiple-image-crossfade-4 {
  0% { opacity: 0; }
  8% { opacity: 1; }
  25% { opacity: 1; }
  33% { opacity: 0; }
  100% { opacity: 0; }
}

@keyframes multiple-image-crossfade-5 {
  0% { opacity: 0; }
  6.66% { opacity: 1; }
  20% { opacity: 1; }
  26.66% { opacity: 0; }
  100% { opacity: 0; }
}

@keyframes multiple-image-crossfade-6 {
  0% { opacity: 0; }
  5.55% { opacity: 1; }
  16.66% { opacity: 1; }
  22.21% { opacity: 0; }
  100% { opacity: 0; }
}

@keyframes multiple-image-crossfade-7 {
  0% { opacity: 0; }
  4.76% { opacity: 1; }
  14.28% { opacity: 1; }
  19.04% { opacity: 0; }
  100% { opacity: 0; }
}

@keyframes multiple-image-crossfade-8 {
  0% { opacity: 0; }
  4.16% { opacity: 1; }
  12.5% { opacity: 1; }
  16.66% { opacity: 0; }
  100% { opacity: 0; }
}

@keyframes multiple-image-crossfade-9 {
  0% { opacity: 0; }
  3.70% { opacity: 1; }
  11.11% { opacity: 1; }
  14.81% { opacity: 0; }
  100% { opacity: 0; }
}

@keyframes multiple-image-crossfade-10 {
  0% { opacity: 0; }
  3.33% { opacity: 1; }
  10% { opacity: 1; }
  13.33% { opacity: 0; }
  100% { opacity: 0; }
}
